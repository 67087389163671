import React from 'react'
import Wrapper from '../styles/Wrapper'
import Header from '../components/Header'
import Button from '../components/Button'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout title="Pagina niet gevonden" content="De pagina die je zoekt bestaat niet. Het zou kunnen dat de pagina is verplaatst of nooit heeft bestaan.">
    <Header />
    <Wrapper>
      <div
        css={{
          margin: '0 auto',
          maxWidth: 650,
          textAlign: 'center',
        }}
      >
        <h1>Pagina bestaat niet</h1>
        <p>
          De pagina die je zoekt bestaat niet. Het zou kunnen dat de pagina is
          verplaatst of nooit heeft bestaan. Je kunt de URL controleren op
          typfouten of het menu gebruiken om te vinden wat je zoekt.
        </p>
        <Button to="/" type="ghost">
          Naar de homepagina
        </Button>
      </div>
    </Wrapper>
  </Layout>
)

export default NotFoundPage
